import { defaultInternationalization } from 'corporate-types';
import { AbortController } from 'node-abort-controller';

// Server side ----------------------------------------------------
// needs the host

export async function getCategoriesByParentCategoryId(
  id: string,
  allSuccessors = false,
  locale?: string
) {
  const controller = new AbortController();
  const signal: any = controller.signal;
  const headers = locale ? { 'x-locale': locale } : undefined;

  const responseCategories = await fetch(
    `${process.env.NEXT_SERVER_SIDE_URL}/api/categories/${id}?allSuccessors=${allSuccessors}`,
    { signal, headers }
  );

  setTimeout(() => controller.abort(), 5000);
  const categories = await responseCategories.json();

  return categories;
}

export async function getProductByIdAndCheckCategoryId(
  productId: string,
  categoryId: string,
  locale = defaultInternationalization,
  internationalizationLocale?: string
) {
  const isEncoded = decodeURIComponent(productId) !== productId;

  let link = '';

  if (isEncoded) {
    link = `${process.env.NEXT_SERVER_SIDE_URL}/api/products/${productId}?categoryId=${categoryId}&seoTagsEnabled=true&locale=${locale}`;
  } else {
    link = `${
      process.env.NEXT_SERVER_SIDE_URL
    }/api/products/${encodeURIComponent(
      productId
    )}?categoryId=${categoryId}&seoTagsEnabled=true&locale=${locale}`;
  }
  if (internationalizationLocale) {
    link = `${link}&internationalizationLocale=${internationalizationLocale}`;
  }
  const controller = new AbortController();
  const signal: any = controller.signal;
  const responseProduct = await fetch(link, { signal });
  setTimeout(() => controller.abort(), 5000);
  const product = await responseProduct.json();
  return product;
}

export async function getProductById(productId: string, locale?: string) {
  const isEncoded = decodeURIComponent(productId) !== productId;

  let link = '';

  if (isEncoded) {
    link = `${process.env.NEXT_SERVER_SIDE_URL}/api/products/${productId}?locale=${locale}`;
  } else {
    link = `${
      process.env.NEXT_SERVER_SIDE_URL
    }/api/products/${encodeURIComponent(productId)}?locale=${locale}`;
  }
  const controller = new AbortController();
  const signal: any = controller.signal;
  const responseProduct = await fetch(link, { signal });
  setTimeout(() => controller.abort(), 5000);
  const product = await responseProduct.json();
  return product;
}

export async function getProductsByCategoryId(
  categoryId: string,
  page: number,
  limit: number,
  sort?: string,
  locale?: string
) {
  const controller = new AbortController();
  const signal: any = controller.signal;
  const headers = locale ? { 'x-locale': locale } : undefined;
  const responseProducts = await fetch(
    `${
      process.env.NEXT_SERVER_SIDE_URL
    }/api/products?categoryId=${categoryId}&page=${page || 1}&limit=${limit}${
      sort ? '&sort=' + sort : ''
    }`,
    { signal, headers }
  );
  setTimeout(() => controller.abort(), 5000);
  const products = await responseProducts.json();
  return products;
}

export async function getProductsBySectorId(
  sectorId: string,
  page: number,
  limit: number,
  sort?: string,
  locale?: string
) {
  const controller = new AbortController();
  const signal: any = controller.signal;
  const headers = locale ? { 'x-locale': locale } : undefined;
  const responseProducts = await fetch(
    `${
      process.env.NEXT_SERVER_SIDE_URL
    }/api/products?sectors=${sectorId}&page=${page || 1}&limit=${limit}${
      sort ? '&sort=' + sort : ''
    }`,
    { signal, headers }
  );
  setTimeout(() => controller.abort(), 5000);
  const products = await responseProducts.json();
  return products;
}

export async function getProductsBySectorAndCategoryId(
  sectorId: string,
  categoryId: string,
  page: number,
  limit: number,
  sort?: string,
  locale?: string
) {
  const controller = new AbortController();
  const signal: any = controller.signal;
  const headers = locale ? { 'x-locale': locale } : undefined;
  const responseProducts = await fetch(
    `${
      process.env.NEXT_SERVER_SIDE_URL
    }/api/products?sectors=${sectorId}&categoryId=${categoryId}&page=${
      page || 1
    }&limit=${limit}${sort ? '&sort=' + sort : ''}`,
    { signal, headers }
  );
  setTimeout(() => controller.abort(), 5000);

  const products = await responseProducts.json();
  return products;
}

export async function getSectorsCategories(locale?: string) {
  const controller = new AbortController();
  const signal: any = controller.signal;
  const headers = locale ? { 'x-locale': locale } : undefined;
  const responseCategories = await fetch(
    `${process.env.NEXT_SERVER_SIDE_URL}/api/categories/categoriesSectors`,
    { signal, headers }
  );
  setTimeout(() => controller.abort(), 5000);

  const categories = await responseCategories.json();
  return categories;
}

// Client side ----------------------------------------------------
// Referring domain name

export async function getCategoriesByParentCategoryIdUI(
  id: string,
  allSuccessors = false,
  locale?: string
) {
  const controller = new AbortController();
  const signal: any = controller.signal;
  const headers = locale ? { 'x-locale': locale } : undefined;
  const responseCategories = await fetch(
    `/api/categories/${id}?allSuccessors=${allSuccessors}&locale=${locale}`,
    { signal, headers }
  );
  setTimeout(() => controller.abort(), 5000);

  const categories = await responseCategories.json();
  return categories;
}
