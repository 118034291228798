// 3rd party
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import {
  capitalizeOnlyFirstLetter,
  hexToRgb,
  isBrowser,
  rgbDataURL,
  serialize,
  toKebabCaseWithAccentsCyrillicCJK,
} from 'corporate-utils';

// Assets
import { ArrowDropDownCircleIcon, NavigateNextIcon } from '../../assets';

// Services
import { getCategoriesByParentCategoryIdUI } from '../../services/dwh';

// Utils
import { getStrapiMedia } from '../../utils/media';
import { getAvailableStringFromCategoryByLocale } from '../../utils/categories';

// Model
import { Category, MegamenuLink } from '../../models/domain/interfaces';

// Style
import {
  Content,
  Cover,
  DescriptionContent,
  ImageContainer,
  ImageProductContainer,
  LinkProduct,
  MegaMenuContainerStyled,
  Product,
  Section,
  SectionCellStyled,
  SectionGridStyled,
  SubTitleProduct,
  Title,
  TitleProduct,
  Voice,
  VoiceLink,
  Voices,
  CloseIconWrapperStyled,
  CloseStyled,
  ScrollToViewContainer,
  NextIconWrapperStyled,
} from './Megamenu.style';
import ImageWithFallback from '../image-with-fallback/ImageWithFallback';
import { DEFAULT_IMAGE } from '../../models/domain/const';

interface IProps {
  megamenuLinks: MegamenuLink[];
  megaMenuMainCategory?: string;
  translations: { [key: string]: any };
  onMouseLeave: (e: Event) => void;
  hover?: any | undefined;
  exitHover?: any | undefined;
  isPrerenderRequest: boolean;
}

interface Megamenu {
  sections: {
    title: string;
    voices: [
      {
        label: string;
        id: string;
        cmsInfo?: any;
      }
    ];
  }[];
  cover?: {
    image: string;
    title: string;
    description: string;
  };
  product?: {
    name: string;
    subtitle: string;
    image: string;
    link: string;
    otherProductsLink: string;
    categoriesCalculatedInfo: Category[][];
  };
}

const MegaMenu: React.FC<IProps> = ({
  onMouseLeave,
  megamenuLinks,
  megaMenuMainCategory,
  translations,
  hover,
  exitHover,
  isPrerenderRequest,
}: any) => {
  // --------------------------------------------------------------------------
  // Variables
  // --------------------------------------------------------------------------

  const { locale } = useRouter();
  const [megamenu, setMegamenu] = useState<Megamenu>({ sections: [] });
  const [indexSection, setIndexSection] = useState<any>({
    indexSection: 0,
    indexVoice: megaMenuMainCategory,
  });
  const [categoryDownloaded, setcategoryDownloaded] = useState<{
    [key: string]: Category[];
  }>({});
  const firstSectionContainer = useRef(null);
  const loadSecondChild = useRef(false);
  const firstSection = useRef(null);
  const router = useRouter();
  const { query } = router;
  const [touch, setTouch] = useState(false);
  const [, setRerender] = useState<number>(0); // Trick to force re-render
  const { categoriesCalculatedInfo } = megamenu?.product || {};

  const mainCategoriesCalculatedInfo = categoriesCalculatedInfo?.[0];
  const secondCategory =
    mainCategoriesCalculatedInfo?.[0] &&
    mainCategoriesCalculatedInfo?.[1] &&
    getAvailableStringFromCategoryByLocale(
      mainCategoriesCalculatedInfo?.[mainCategoriesCalculatedInfo?.[2] ? 1 : 0],
      router.locale,
      'packages/corporate-ui/src/components/cards/card-other-products/CardOtherProducts.tsx'
    );
  const thirdCategory =
    mainCategoriesCalculatedInfo?.[1] &&
    getAvailableStringFromCategoryByLocale(
      mainCategoriesCalculatedInfo?.[mainCategoriesCalculatedInfo?.[2] ? 2 : 1],
      router.locale,
      'packages/corporate-ui/src/components/cards/card-other-products/CardOtherProducts.tsx'
    );

  // --------------------------------------------------------------------------
  // Functions
  // --------------------------------------------------------------------------

  const donwloadAllCategories = useCallback(
    async ({ voices } = {}) => {
      if (voices) {
        const categories = await Promise.all(
          voices?.map(async (voice: any) => {
            if (categoryDownloaded[voice?.id]) {
              return categoryDownloaded[voice?.id];
            } else {
              const categoriesToReturn =
                await getCategoriesByParentCategoryIdUI(
                  voice?.id,
                  false,
                  locale
                );
              const filteredCategories = categoriesToReturn.filter(
                (category: any) =>
                  category.idFather === voice?.id || category?.depth === 0
              );
              return filteredCategories;
            }
          })
        );
        const categoryToDownload = categories?.reduce?.((acc, items) => {
          const father = items?.[0];
          if (father?.id && !acc[father?.id]) {
            acc[father?.id] = items?.slice?.(0, items?.length);
          }
          return acc;
        }, categoryDownloaded || {});

        setcategoryDownloaded(categoryToDownload);
        setRerender(Date.now());
      }
    },
    [categoryDownloaded, locale]
  );

  const downloadSecondChild = useCallback(async () => {
    const allIdsOfCategoriesOfSecondChild: any = {
      voices: [],
    };

    megamenuLinks?.forEach?.((megamenuLink: MegamenuLink) => {
      megamenuLink?.firstChild?.forEach?.((categoryChild: Category) => {
        allIdsOfCategoriesOfSecondChild?.voices?.push?.(categoryChild);
      });
    });

    loadSecondChild.current = true;
    await donwloadAllCategories(allIdsOfCategoriesOfSecondChild);
  }, [donwloadAllCategories, megamenuLinks]);

  // TODO: can be an utils?
  const gererateUrlInformation = (
    index: number,
    voice: any,
    subCategory: any
  ): {
    url: string;
    queryCopy: any;
  } => {
    let url = `${toKebabCaseWithAccentsCyrillicCJK(
      megamenu?.sections?.[index]?.title
    )}/${toKebabCaseWithAccentsCyrillicCJK(voice?.label)}`;

    const queryCopy = {
      ...query,
    };

    if (subCategory) {
      const secondColumName = megamenu?.sections[0]?.voices?.find?.(
        (voice: any) => voice?.id === subCategory?.firstColumId
      )?.label;
      url = secondColumName
        ? `${toKebabCaseWithAccentsCyrillicCJK(
          megamenu?.sections?.[index]?.title
        )}/${toKebabCaseWithAccentsCyrillicCJK(secondColumName)}`
        : '';
      queryCopy[translations?.queryParmas?.subcategory] = '' + voice?.id;
    } else {
      delete queryCopy?.[translations?.queryParmas?.subcategory];
    }
    if (!url) url = '/';
    delete queryCopy?.slug;
    delete queryCopy?.q;
    delete queryCopy?.page;
    delete queryCopy?.pageSize;

    return {
      url,
      queryCopy,
    };
  };

  // TODO: can be an utils?
  const generateUrl =
    (voice: any, index: number, subCategory?: any) => (): string => {
      const { url, queryCopy } = gererateUrlInformation(
        index,
        voice,
        subCategory
      );

      const query = serialize(queryCopy);

      return `${url}${query ? `?${query}` : ''}`;
    };

  // TODO: useCallback
  const onClickVoice =
    (voice: any, index: number, subCategory?: any) => (e: any) => {
      const { url, queryCopy } = gererateUrlInformation(
        index,
        voice,
        subCategory
      );
      if (
        router &&
        (router?.asPath?.split?.('?')[0] !== `/${url}` ||
          (router?.asPath?.split?.('?')[0] === `/${url}` &&
            ((queryCopy &&
              queryCopy[translations?.queryParmas?.subcategory] !==
                router?.query?.[translations?.queryParmas?.subcategory]) ||
              !queryCopy ||
              (queryCopy &&
                Object.keys(queryCopy)?.length !==
                  Object.keys(router?.query)?.filter((key) => key !== 'slug')
                    ?.length))))
      ) {
        router.push(
          {
            pathname: url,
            query: url != '/' ? queryCopy : null,
          },
          {
            pathname: url,
            query: url != '/' ? queryCopy : null,
          },
          { locale: router?.locale }
        );
        onMouseLeave(e);
        setIndexSection({
          indexSection: 0,
          indexVoice: megaMenuMainCategory,
        });
      }
    };

  // TODO: useCallback
  const scrollDown = () => {
    const scrollOffset = (firstSectionContainer?.current as any)?.scrollTop;

    (firstSectionContainer?.current as any)?.scroll?.({
      top: (scrollOffset || 0) + 50,
    });
  };

  const closeMenuOnResize = useCallback(() => {
    onMouseLeave({}, true);
    setIndexSection({
      indexSection: 0,
      indexVoice: megaMenuMainCategory,
    });
  }, [megaMenuMainCategory, onMouseLeave]);

  // --------------------------------------------------------------------------
  // useEffects
  // --------------------------------------------------------------------------

  // When user changes the main megamenulink ???
  useEffect(
    () => {
      const firstDiv =
        isBrowser() && document?.getElementsByTagName?.('body')?.[0];
      const scrollFixerManual =
        isBrowser() && document?.getElementById?.('manual-scroll-fixer');

      if (megaMenuMainCategory && megaMenuMainCategory !== '') {
        if (firstDiv && scrollFixerManual && firstDiv.style) {
          firstDiv.style.overflowY = 'hidden';
          scrollFixerManual.style.display = 'block';
        }

        const linkSelected = megamenuLinks?.find?.(
          ({ idDwh }: { idDwh: string }) => {
            return ~~idDwh === ~~megaMenuMainCategory;
          }
        );

        const {
          firstChild: categories,
          recommendedProduct,
          coverImage,
          coverDescription,
          coverTitle,
        } = linkSelected || { firstChild: [], recommendedProduct: {} };

        const localMegamenu: any = {
          sections: [],
          product: {
            name: recommendedProduct?.nameFather,
            subtitle: recommendedProduct?.description,
            link: recommendedProduct?.link,
            otherProductsLink: recommendedProduct?.otherProductsLink,
            image:
              recommendedProduct?.images && recommendedProduct?.images?.[0],
            categoriesCalculatedInfo:
              recommendedProduct?.categoriesCalculatedInfo,
          },
          cover: {
            title: coverTitle,
            description: coverDescription,
            image: getStrapiMedia(coverImage?.data?.attributes?.url),
          },
        };

        if (categories && categories?.length > 1) {
          const father = categories?.filter?.(
            (category: any) => category?.depth === 0
          );
          const children = categories?.filter?.(
            (category: any) => category?.depth === 1
          );
          localMegamenu.sections[indexSection?.indexSection] = {
            title:
              father?.[0] &&
              getAvailableStringFromCategoryByLocale(
                father[0],
                locale,
                'packages/corporate-ui/src/components/megamenu/Megamenu.tsx'
              ),
            id: father?.[0]?.id,
            cmsInfo: father?.[0]?.cmsInfo,
            voices: children?.map((c: any) => {
              return {
                label:
                  c &&
                  capitalizeOnlyFirstLetter(
                    getAvailableStringFromCategoryByLocale(
                      c,
                      locale,
                      'packages/corporate-ui/src/components/megamenu/Megamenu.tsx'
                    )
                  ),
                id: c?.id,
                cmsInfo: c?.cmsInfo,
              };
            }),
          };
        }

        localMegamenu.sections = localMegamenu?.sections?.filter?.(
          (section: any) => !!section
        );

        donwloadAllCategories(
          localMegamenu?.sections?.[indexSection?.indexSection]
        );

        setMegamenu(localMegamenu);
      } else {
        if (firstDiv && scrollFixerManual && firstDiv.style) {
          firstDiv.style.overflowY = 'auto';
          scrollFixerManual.style.display = 'none';
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [megaMenuMainCategory, megamenuLinks]
  );

  // When user changes the main megamenulink ??
  useEffect(
    () => {
      const categories = categoryDownloaded?.[indexSection?.indexVoice];

      const localMegamenu: any = {
        ...megamenu,
      };

      for (
        let index = indexSection?.indexSection;
        index < localMegamenu?.sections?.length;
        index++
      ) {
        localMegamenu.sections[index] = undefined;
      }

      if (categories && categories.length > 1) {
        const father = categories?.filter?.(
          (category: any) => category?.depth === 0
        );
        const children = categories?.filter?.(
          (category: any) => category?.depth === 1
        );
        localMegamenu.sections[indexSection?.indexSection] = {
          title:
            father?.[0] &&
            getAvailableStringFromCategoryByLocale(
              father?.[0],
              locale,
              'packages/corporate-ui/src/components/megamenu/Megamenu.tsx'
            ),
          id: father?.[0]?.id,
          voices: children?.map?.((c: any) => {
            return {
              label:
                c &&
                capitalizeOnlyFirstLetter(
                  getAvailableStringFromCategoryByLocale(
                    c,
                    locale,
                    'packages/corporate-ui/src/components/megamenu/Megamenu.tsx'
                  )
                ),
              id: c?.id,
            };
          }),
        };
      }

      localMegamenu.sections = localMegamenu?.sections?.filter?.(
        (section: any) => !!section
      );

      donwloadAllCategories(
        localMegamenu?.sections?.[indexSection?.indexSection]
      );

      setMegamenu(localMegamenu);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [indexSection]
  );

  // When categories are cached
  useEffect(() => {
    setRerender(Date.now());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryDownloaded]);

  // When user changes the first sections, this is necessary for rerender the scroll icon
  useEffect(() => {
    setRerender(Date.now());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [megamenu?.sections?.[0]]);

  // When user changes the first sections, this is necessary for rerender the scroll icon
  useEffect(() => {
    megaMenuMainCategory !== '' &&
      megaMenuMainCategory !== undefined &&
      loadSecondChild.current === false &&
      downloadSecondChild();
  }, [downloadSecondChild, megaMenuMainCategory]);

  // When the component load the first time
  useEffect(
    () => {
      isBrowser() && window?.addEventListener('resize', closeMenuOnResize);
      return () => {
        isBrowser() && window?.removeEventListener('resize', closeMenuOnResize);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // --------------------------------------------------------------------------
  // Render
  // --------------------------------------------------------------------------

  return megaMenuMainCategory !== '' && megamenu?.sections?.length ? (
    <>
      <MegaMenuContainerStyled
        onMouseLeave={(e: any) => {
          hover?.current && clearTimeout(hover?.current);
          if (exitHover?.current) {
            clearTimeout(exitHover?.current);
          }
          exitHover.current = setTimeout(() => {
            onMouseLeave(e);
            setIndexSection({
              indexSection: 0,
              indexVoice: megaMenuMainCategory,
            });
          }, 180);
        }}
      >
        <SectionGridStyled>
          <SectionCellStyled
            columnStart={1}
            rowStart={1}
            rowEnd={4}
            ref={firstSectionContainer}
          >
            {megamenu?.sections?.[0] && (
              <>
                <Section noMargin={true} ref={firstSection}>
                  <Title>
                    {capitalizeOnlyFirstLetter(megamenu?.sections?.[0]?.title)}
                  </Title>
                  <Voices>
                    {megamenu?.sections?.[0]?.voices?.map?.(
                      (voice: any, indexVoice: number) => {
                        return (
                          <Voice
                            key={indexVoice}
                            selected={voice?.id === indexSection?.firstColumId}
                            onMouseOver={() => {
                              setIndexSection({
                                indexSection: 1,
                                indexVoice: voice?.id,
                                firstColumId: voice?.id,
                              });
                            }}
                            onClick={!touch ? onClickVoice(voice, 0) : () => {}}
                            onTouchStart={(e: any) => {
                              setTouch(true);
                              if (voice?.id === indexSection?.firstColumId) {
                                onClickVoice(voice, 0)(e);
                              }
                            }}
                          >
                            <Link
                              href={generateUrl(voice, 0)()}
                              passHref
                              prefetch={false}
                            >
                              <VoiceLink
                                onClick={(
                                  e: React.MouseEvent<HTMLAnchorElement>
                                ) => e?.preventDefault?.()}
                              >
                                <span>{voice?.label || ''}</span>
                                <NextIconWrapperStyled>
                                  {categoryDownloaded?.[voice?.id]?.length >
                                  1 ? (
                                      <NavigateNextIcon></NavigateNextIcon>
                                    ) : (
                                      ''
                                    )}
                                </NextIconWrapperStyled>
                              </VoiceLink>
                            </Link>
                          </Voice>
                        );
                      }
                    )}
                  </Voices>
                </Section>
                {(firstSection?.current as any)?.clientHeight >
                  (firstSectionContainer?.current as any)?.clientHeight -
                    32 && (
                  <ScrollToViewContainer onClick={scrollDown}>
                    {' '}
                    <ArrowDropDownCircleIcon></ArrowDropDownCircleIcon>{' '}
                  </ScrollToViewContainer>
                )}
              </>
            )}
          </SectionCellStyled>
          {megamenu?.sections?.length > 1 && (
            <React.Fragment>
              <SectionCellStyled
                columnStart={2}
                rowStart={1}
                rowEnd={4}
                noMargin={!megamenu?.sections?.[2]}
              >
                {megamenu?.sections?.[1] ? (
                  <Section noMargin={true}>
                    <Title>
                      {capitalizeOnlyFirstLetter(
                        megamenu?.sections?.[1]?.title
                      )}
                    </Title>
                    <Voices>
                      {megamenu?.sections?.[1]?.voices?.map(
                        (voice: any, indexVoice: number) => {
                          return (
                            <Voice
                              key={indexVoice}
                              selected={
                                voice?.id === indexSection?.secondColumnId
                              }
                              onMouseOver={() => {
                                setIndexSection({
                                  indexSection: 2,
                                  indexVoice: voice?.id,
                                  firstColumId: indexSection?.firstColumId,
                                  secondColumnId: voice?.id,
                                });
                              }}
                              onClick={
                                !touch
                                  ? onClickVoice(voice, 0, {
                                    indexSection: 2,
                                    indexVoice: voice?.id,
                                    firstColumId: indexSection?.firstColumId,
                                    secondColumnId: voice?.id,
                                  })
                                  : () => {}
                              }
                              onTouchStart={(e: any) => {
                                setTouch(true);
                                if (
                                  voice?.id === indexSection?.secondColumnId
                                ) {
                                  onClickVoice(voice, 0, {
                                    indexSection: 2,
                                    indexVoice: voice?.id,
                                    firstColumId: indexSection?.firstColumId,
                                    secondColumnId: voice?.id,
                                  })(e);
                                }
                              }}
                            >
                              <Link
                                href={generateUrl(voice, 0, {
                                  indexSection: 2,
                                  indexVoice: voice?.id,
                                  firstColumId: indexSection?.firstColumId,
                                  secondColumnId: voice?.id,
                                })()}
                                passHref
                                prefetch={false}
                              >
                                <VoiceLink
                                  onClick={(
                                    e: React.MouseEvent<HTMLAnchorElement>
                                  ) => e?.preventDefault?.()}
                                >
                                  <span>{voice?.label || ''}</span>
                                  <NextIconWrapperStyled>
                                    {categoryDownloaded?.[voice?.id]?.length >
                                    1 ? (
                                        <NavigateNextIcon></NavigateNextIcon>
                                      ) : (
                                        ''
                                      )}
                                  </NextIconWrapperStyled>
                                </VoiceLink>
                              </Link>
                            </Voice>
                          );
                        }
                      )}
                    </Voices>
                  </Section>
                ) : (
                  <span></span>
                )}
              </SectionCellStyled>
              <SectionCellStyled columnStart={3} rowStart={1} rowEnd={4}>
                {megamenu?.sections?.[2] ? (
                  <Section noMargin={true}>
                    <Title>
                      {capitalizeOnlyFirstLetter(
                        megamenu?.sections?.[2]?.title
                      )}
                    </Title>
                    <Voices>
                      {megamenu?.sections?.[2]?.voices?.map?.(
                        (voice: any, indexVoice: number) => {
                          return (
                            <Voice
                              key={indexVoice}
                              onMouseOver={() => {
                                setIndexSection({
                                  indexSection: 3,
                                  indexVoice: voice?.id,
                                  firstColumId: indexSection?.firstColumId,
                                  secondColumnId: indexSection?.secondColumnId,
                                });
                              }}
                              onClick={
                                !touch
                                  ? onClickVoice(voice, 0, {
                                    indexSection: 3,
                                    indexVoice: voice?.id,
                                    firstColumId: indexSection?.firstColumId,
                                    secondColumnId:
                                        indexSection?.secondColumnId,
                                  })
                                  : () => {}
                              }
                              onTouchStart={(e: any) => {
                                setTouch(true);
                                onClickVoice(voice, 0, {
                                  indexSection: 3,
                                  indexVoice: voice?.id,
                                  firstColumId: indexSection?.firstColumId,
                                  secondColumnId: indexSection?.secondColumnId,
                                })(e);
                              }}
                            >
                              <Link
                                href={generateUrl(voice, 0, {
                                  indexSection: 3,
                                  indexVoice: voice?.id,
                                  firstColumId: indexSection?.firstColumId,
                                  secondColumnId: indexSection?.secondColumnId,
                                })()}
                                passHref
                                prefetch={false}
                              >
                                <VoiceLink
                                  onClick={(
                                    e: React.MouseEvent<HTMLAnchorElement>
                                  ) => e?.preventDefault?.()}
                                >
                                  {voice?.label || ''}
                                </VoiceLink>
                              </Link>
                            </Voice>
                          );
                        }
                      )}
                    </Voices>
                  </Section>
                ) : (
                  <span></span>
                )}
              </SectionCellStyled>
            </React.Fragment>
          )}
          <SectionCellStyled
            noMargin={true}
            columnStart={4}
            rowStart={1}
            rowEnd={4}
          >
            <CloseIconWrapperStyled>
              <CloseStyled
                onClick={(e: any) => {
                  hover?.current && clearTimeout(hover?.current);
                  exitHover?.current && clearTimeout(exitHover?.current);
                  onMouseLeave(e);
                  setIndexSection({
                    indexSection: 0,
                    indexVoice: megaMenuMainCategory,
                  });
                }}
              ></CloseStyled>
            </CloseIconWrapperStyled>
            {megamenu?.product && (
              <Product>
                <TitleProduct>
                  {megamenu?.product?.name?.toUpperCase()}
                </TitleProduct>
                <SubTitleProduct>{megamenu?.product?.subtitle}</SubTitleProduct>
                <ImageProductContainer>
                  {megamenu?.product?.image && (
                    <ImageWithFallback
                      layout="fill"
                      objectFit="contain"
                      placeholder="blur"
                      blurDataURL={rgbDataURL(
                        hexToRgb('ffffff')?.[0],
                        hexToRgb('ffffff')?.[1],
                        hexToRgb('ffffff')?.[2]
                      )}
                      src={getStrapiMedia(megamenu?.product?.image || '')}
                      alt={
                        `${secondCategory ?? ''} ${secondCategory ? '-' : ''} ${
                          thirdCategory ?? ''
                        } ${thirdCategory ? '-' : ''} ${
                          megamenu?.product?.name ?? ''
                        } ${megamenu?.product?.name ? '-' : ''} ${
                          process?.env?.NEXT_PUBLIC_COMPANY_NAME ?? ''
                        }` || ''
                      }
                      fallbackSrc={DEFAULT_IMAGE}
                      isPrerenderRequest={isPrerenderRequest}
                    />
                  )}
                </ImageProductContainer>
                {megamenu?.product?.link && (
                  <Link
                    href={megamenu?.product?.link}
                    passHref
                    prefetch={false}
                  >
                    <LinkProduct>
                      {translations?.components?.megamenu?.product?.goToProduct}
                    </LinkProduct>
                  </Link>
                )}
                {megamenu?.product?.otherProductsLink && (
                  <Link
                    href={megamenu?.product?.otherProductsLink}
                    passHref
                    prefetch={false}
                  >
                    <LinkProduct>
                      {
                        translations?.components?.megamenu?.product
                          ?.seeAllRelatedProducts
                      }
                    </LinkProduct>
                  </Link>
                )}
              </Product>
            )}
          </SectionCellStyled>

          {megamenu?.sections?.length === 1 && (
            <SectionCellStyled
              columnStart={2}
              rowStart={1}
              columnnEnd={4}
              rowEnd={4}
            >
              {megamenu?.sections?.length === 1 && megamenu?.cover && (
                <Cover>
                  <Content>
                    <DescriptionContent>
                      {megamenu?.cover?.description}
                    </DescriptionContent>
                  </Content>
                  <ImageContainer>
                    {megamenu?.cover?.image && (
                      <ImageWithFallback
                        layout="fill"
                        objectFit="contain"
                        placeholder="blur"
                        blurDataURL={rgbDataURL(
                          hexToRgb('ffffff')?.[0],
                          hexToRgb('ffffff')?.[1],
                          hexToRgb('ffffff')?.[2]
                        )}
                        src={getStrapiMedia(megamenu?.cover?.image || '')}
                        alt={megamenu?.cover?.description || ''}
                        fallbackSrc={DEFAULT_IMAGE}
                        isPrerenderRequest={isPrerenderRequest}
                      />
                    )}
                  </ImageContainer>
                </Cover>
              )}
            </SectionCellStyled>
          )}
        </SectionGridStyled>
      </MegaMenuContainerStyled>
    </>
  ) : (
    <></>
  );
};

export default MegaMenu;
