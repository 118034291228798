import { styled } from '@mui/material';
import { CloseIcon } from '../../assets';

export const Section = styled('section', {
  shouldForwardProp: (prop) => prop !== 'noMargin',
})(({ theme, noMargin }) => ({
  paddingLeft: '2rem',
  paddingRight: '2rem',
  borderRight: `1px solid ${theme.colors.primary}`,
  ...(noMargin && { borderRight: 'none' }),
}));

export const ScrollToViewContainer = styled('div')(({ theme }) => ({
  position: 'sticky',
  bottom: '0',
  height: theme.spacings.unit(20 / 4),
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  right: 0,
  backgroundColor: 'white',
  paddingBottom: theme.spacings.unit(28 / 4),
  cursor: 'pointer',
  svg: {
    margin: '0 auto',
    marginTop: theme.spacings.unit(30 / 4),
    color: theme.colors.borderMegamenu,
  },
}));

export const Title = styled('h2')(({ theme }) => ({
  color: theme.colors.secondaryText,
  fontWeight: 500,
  wordBreak: 'break-word',
}));

export const Voices = styled('ul')(() => ({
  height: '100%',
  listStyleType: 'none',
  padding: '0px',
}));

export const Cover = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '100%',
}));

export const Voice = styled('li', {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  color: theme.colors.secondaryText,
  position: 'relative',
  marginTop: theme.spacings.unit(8 / 4),
  marginBottom: theme.spacings.unit(8 / 4),
  svg: {
    color: theme.colors.secondaryText,
  },

  '&:hover': {
    background: theme.colors.primary,
    color: theme.colors.white,
    cursor: 'pointer',
    svg: {
      color: theme.colors.white,
    },
  },

  ...(selected && {
    background: theme.colors.primary,
    color: theme.colors.white,
    cursor: 'pointer',
    svg: {
      color: theme.colors.white,
    },
  }),
}));

export const VoiceLink = styled('a')(({ theme }) => ({
  display: 'flex',
  color: 'inherit',
  textDecoration: 'none',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacings.unit(4 / 4),
  minHeight: theme.spacings.unit(10.5),
  '&:hover': {
    padding: theme.spacings.unit(4 / 4),
    color: 'inherit',
  },
}));

export const ImageContainer = styled('div')(({ theme }) => ({
  height: '50%',
  minHeight: theme.spacings.unit(50),
  width: '100%',
  position: 'relative',
}));

export const ImageProductContainer = styled('div')(({ theme }) => ({
  marginBottom: '5rem',
  position: 'relative',
  width: '100%',
  aspectRatio: '1/1',
  minHeight: theme.spacings.unit(50),
}));

export const Content = styled('div')(() => ({
  padding: '2rem',
}));

export const DescriptionContent = styled('h2')(({ theme }) => ({
  fontWeight: 300,
  color: theme.colors.secondaryText,
  width: '350px', // theme
}));

export const Product = styled('div')(({ theme }) => ({
  paddingLeft: '2rem',
  paddingRight: '2rem',
  overflow: 'hidden',
}));

export const TitleProduct = styled('h2')(({ theme }) => ({
  fontWeight: 500,
  color: theme.colors.primary,
}));

export const LinkProduct = styled('a')(({ theme }) => ({
  display: 'block',
  color: theme.colors.secondaryText,
  marginTop: '1rem', // theme
}));

export const SubTitleProduct = styled('p')(({ theme }) => ({
  color: theme.colors.secondaryText,
}));

export const TitleDescription = styled('h1')(({ theme }) => ({
  fontSize: '1.5rem', // theme
  margin: '0px', // theme
  fontWeight: 300,
  [theme.breakpointsMediaQuery.up('lg')]: {
    width: '50%',
  },
}));

export const Description = styled('p')(() => ({
  fontSize: '1rem', // theme
  lineHeight: '2rem', // theme
  fontWeight: 300,
}));

export const MegaMenuContainerStyled = styled('div')(({ theme }) => ({
  height: `calc(100vh - ${theme.megamenu.heigthFixer} - ${theme.megamenu.marginBottom})`, // theme
  [theme.breakpointsMediaQuery.up('xl')]: {
    maxWidth: `calc(${theme.megamenu.maxWidth} - ${theme.spacings.unit(
      20 / 4
    )})`,
  },
  [theme.breakpointsMediaQuery.down('xl')]: {
    padding: `${theme.spacings.unit(0 / 4)} ${theme.spacings.unit(
      26
    )} ${theme.spacings.unit(8 / 4)} ${theme.spacings.unit(26)}`,
  },
  margin: '0 auto',
  overflow: 'hidden',
}));

export const SectionGridStyled = styled('div')(() => ({
  margin: '0 auto',
  width: '100%',
  display: 'grid',
  height: 'calc(100% - 2rem)',
  gridTemplateColumns: '25% 25% 25% 25%',
  gridTemplateRows: '33% 33% 1fr',
  marginTop: '1rem',
}));

export const SectionCellStyled = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'columnStart' &&
    prop !== 'rowStart' &&
    prop !== 'columnnEnd' &&
    prop !== 'rowEnd' &&
    prop !== 'flex' &&
    prop !== 'noMargin',
})(({ theme, flex, noMargin, columnStart, rowStart, columnnEnd, rowEnd }) => ({
  gridColumnStart: columnStart,
  gridColumnEnd: columnnEnd ? columnnEnd : 'auto',
  gridRowStart: rowStart,
  gridRowEnd: rowEnd,
  overflow: 'auto',
  position: 'relative',
  ...(flex && { display: 'flex' }),
  borderRight: `1px solid ${theme.colors.sectionDivider}`,
  ...(noMargin && { borderRight: 'none' }),
}));

export const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  padding: `${theme.spacings.unit(5)}`,
  overflow: 'auto',
  height: '100%',
}));

export const ImageContainerStyled = styled('div')(() => ({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  zIndex: -3,
  height: '100%',
  width: '100%',
}));

export const OverlayStyled = styled('div', {
  shouldForwardProp: (prop) => !['overlay'].includes(prop as string),
})(({ theme, overlay }) => ({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  zIndex: -2,
  boxShadow: `inset 0 0 0 ${theme.spacings.unit(500)} ${
    overlay ? overlay + '80' : 'transparent'
  }`,
}));

export const CoverContainer = styled(
  'div',
  {}
)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  color: theme.colors.white,
  '&:after': {
    opacity: 1,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    zIndex: -1,
  },
}));

export const CloseIconWrapperStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100%',
  padding: `0 ${theme.spacings.unit(5)}`,
}));

const CloseComponent = ({ ...props }: { onClick?: (e?: any) => void }) => {
  return (
    <span>
      <CloseIcon {...props} size={'1.2em'} />
    </span>
  );
};

export const CloseStyled = styled(CloseComponent)(({ theme }: any) => ({
  cursor: 'pointer',
  color: theme.colors.primary,
}));

export const NextIconWrapperStyled = styled('span')(({ theme }) => ({
  color: theme.colors.borderMegamenu,
  svg: {
    marginTop: theme.spacings.unit(1),
  },
}));
